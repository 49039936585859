// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/legacy/components/ErrorBoundary.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/legacy/components/ErrorBoundary.tsx");
  import.meta.hot.lastModified = "1716509689197.9592";
}
// REMIX HMR END

import { AlertTriangle } from 'lucide-react';
import { useLogger } from '~/logging';
import { useRunOnce } from '~/utils/general';
import { BackFromErrorButton } from '../../ui/molecules/BackFromErrorButton';
export function ErrorBoundary({
  error
}) {
  _s();
  const logger = useLogger();
  useRunOnce(() => {
    logger.error('Root ErrorBoundary hit', error);
  });
  let {
    message
  } = error;

  // Hard-code a friendlier error message for Azure's 502 errors (NS-8788).
  if (message.includes('502 Bad Gateway')) {
    message = 'Encountered a gateway error. Please try again.';
  }
  return <main className='flex h-full w-full flex-col items-center'>
      <article className='flex h-full max-w-md flex-col items-center justify-center text-center'>
        <AlertTriangle size={80} className='mb-3 text-gray-400' />
        <h3 className='mb-2 text-xl font-bold'>Something went wrong</h3>
        <p className='mb-4 max-w-sm text-sm text-gray-500 dark:text-gray-400'>
          {message}
        </p>
        <BackFromErrorButton />
      </article>
    </main>;
}
_s(ErrorBoundary, "Qh4RzHpaqeR19N7haAVfXRCIZH0=", false, function () {
  return [useLogger, useRunOnce];
});
_c = ErrorBoundary;
var _c;
$RefreshReg$(_c, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;