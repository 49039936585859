// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/molecules/Toaster.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/molecules/Toaster.tsx");
  import.meta.hot.lastModified = "1720663555990.069";
}
// REMIX HMR END

import { UilInfoCircle } from '@iconscout/react-unicons';
import { Toast, useToast } from '~/ui/atoms/Toast';
import { cn } from '~/utils/cn';
import { AlertTriangleFilled } from '../icons/alert-icons/AlertTriangleFilled';
import { ErrorCircle } from '../icons/ErrorCircle';
import { IconCheckbox } from '../icons/IconCheckbox';
const bothRows = 'row-start-1 row-end-[span_2] flex-none';
const iconProps = {
  className: bothRows,
  size: 24
};
const toastIcons = {
  success: <IconCheckbox shape='circle' isChecked variant='success' {...iconProps} />,
  error: <ErrorCircle {...iconProps} />,
  warning: <AlertTriangleFilled {...iconProps} variant='warning' />,
  info: <UilInfoCircle {...iconProps} />
};
export function Toaster() {
  _s();
  const {
    toasts
  } = useToast();
  return <Toast.Provider>
      {toasts.map(({
      id,
      title,
      description,
      action,
      variant = 'info',
      dismissible,
      className,
      ...props
    }) => <Toast key={id} className={cn(className, !dismissible && 'pointer-events-none')} {...props}>
            <div className='flex w-full flex-row items-center gap-x-2'>
              {toastIcons[variant] ?? <UilInfoCircle {...iconProps} />}
              {(title != null || description != null) && <div className='flex grow flex-col gap-y-1'>
                  {title && <Toast.Title className='col-start-2'>{title}</Toast.Title>}
                  {description && <Toast.Description className='col-start-2'>
                      {description}
                    </Toast.Description>}
                </div>}
              <div className={cn('pointer-events-auto col-start-3', bothRows)}>
                {action}
              </div>
            </div>
            {dismissible === true && <Toast.Close />}
          </Toast>)}
      <Toast.Viewport />
    </Toast.Provider>;
}
_s(Toaster, "1YTCnXrq2qRowe0H/LBWLjtXoYc=", false, function () {
  return [useToast];
});
_c = Toaster;
var _c;
$RefreshReg$(_c, "Toaster");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;