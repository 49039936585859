// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/icons/ErrorCircle.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/icons/ErrorCircle.tsx");
  import.meta.hot.lastModified = "1702569433515.8843";
}
// REMIX HMR END

export function ErrorCircle({
  size = 16,
  ...props
}) {
  return <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.3346 7.99984C15.3346 12.0499 12.0514 15.3332 8.0013 15.3332C3.95121 15.3332 0.667969 12.0499 0.667969 7.99984C0.667969 3.94975 3.95121 0.666504 8.0013 0.666504C12.0514 0.666504 15.3346 3.94975 15.3346 7.99984ZM8.00182 4.6665C8.37001 4.6665 8.66849 4.96498 8.66849 5.33317V7.99984C8.66849 8.36803 8.37001 8.6665 8.00182 8.6665C7.63363 8.6665 7.33516 8.36803 7.33516 7.99984V5.33317C7.33516 4.96498 7.63363 4.6665 8.00182 4.6665ZM7.33516 10.6665C7.33516 10.2983 7.63363 9.99984 8.00182 9.99984H8.00849C8.37668 9.99984 8.67516 10.2983 8.67516 10.6665C8.67516 11.0347 8.37668 11.3332 8.00849 11.3332H8.00182C7.63363 11.3332 7.33516 11.0347 7.33516 10.6665Z' fill='#EF4444' />
    </svg>;
}
_c = ErrorCircle;
var _c;
$RefreshReg$(_c, "ErrorCircle");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;