// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json } from '@remix-run/node';
import { Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useCatch, useNavigation } from '@remix-run/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@total-typescript/ts-reset';
import hljs from 'highlight.js/styles/github-dark.css';
import { AlertTriangle } from 'lucide-react';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHydrated } from 'remix-utils';
import { proxy } from 'valtio';
import { supportedAuthProviders } from '@/auth';
import { DEFAULT_LOG_LEVEL, LogLevel } from '@/constants';
import { isEnumMatch } from '@/guards';
import { ClientPortal } from '~/ui/atoms/Portal';
import { Avatar } from '~/ui/molecules/Avatar';
import { Toaster } from '~/ui/molecules/Toaster';
import tailwindStyles from '~/styles/app.css';
import { useNonce } from '~/contexts/NonceContext';
import { ErrorBoundary as ErrorBoundaryContent } from '~/legacy/components/ErrorBoundary';
import { useLogger } from '~/logging';
import { useEnv, useOptionalUser } from '~/utils';
import { useRunOnce } from '~/utils/general';
import { queryClient } from '~/utils/query';
import packageJSON from '../package.json';
import { API } from './client/api.server';
import { BackFromErrorButton } from './ui/molecules/BackFromErrorButton';
export const meta = ({
  data
}) => ({
  charset: 'utf-8',
  title: data.env?.TITLE ?? 'Numbers Station',
  viewport: 'width=device-width,initial-scale=1'
});
export const links = () => [{
  rel: 'preload',
  href: '/fonts/inter-latin-ext.woff2',
  crossOrigin: 'anonymous',
  type: 'font/woff2',
  as: 'font'
}, {
  rel: 'preload',
  href: '/fonts/inter-latin.woff2',
  crossOrigin: 'anonymous',
  type: 'font/woff2',
  as: 'font'
}, {
  rel: 'preload',
  href: '/fonts/courier-400-normal-latin-ext.woff2',
  crossOrigin: 'anonymous',
  type: 'font/woff2',
  as: 'font'
}, {
  rel: 'preload',
  href: '/fonts/courier-400-normal-latin.woff2',
  crossOrigin: 'anonymous',
  type: 'font/woff2',
  as: 'font'
}, {
  rel: 'preload',
  href: '/fonts/inter.css',
  as: 'style'
}, {
  rel: 'preload',
  href: '/fonts/courier.css',
  as: 'style'
}, {
  rel: 'preload',
  href: tailwindStyles,
  as: 'style'
}, {
  rel: 'preload',
  href: hljs,
  as: 'style'
}, {
  rel: 'stylesheet',
  href: '/fonts/inter.css'
}, {
  rel: 'stylesheet',
  href: '/fonts/courier.css'
}, {
  rel: 'stylesheet',
  href: tailwindStyles
}, {
  rel: 'stylesheet',
  href: hljs
}, {
  rel: 'shortcut icon',
  href: '/favicon.ico?v=2',
  type: 'image/x-icon'
}, {
  rel: 'icon',
  href: '/favicon.ico?v=2',
  type: 'image/x-icon'
}];
export const inIframe = proxy({
  account: null
});
export const loader = async ({
  request,
  context
}) => {
  const authProvider = process.env.AUTH_PROVIDER;
  const env = {
    // feature flags
    LOG_LEVEL: isEnumMatch(process.env.LOG_LEVEL, LogLevel) ? process.env.LOG_LEVEL : DEFAULT_LOG_LEVEL,
    ER: process.env.ER ? process.env.ER === 'true' : false,
    BB: process.env.BB ? process.env.BB === 'true' : false,
    AUTH_PROVIDER: supportedAuthProviders.includes(authProvider) ? authProvider : 'ns',
    IMPORT_NODE_UPDATE_SOURCE: process.env.IMPORT_NODE_UPDATE_SOURCE ? process.env.IMPORT_NODE_UPDATE_SOURCE === 'true' : false,
    SIGNUP: process.env.SIGNUP ? process.env.SIGNUP === 'true' : true,
    BIGQUERY: process.env.BIGQUERY ? process.env.BIGQUERY === 'true' : false,
    REDSHIFT: process.env.REDSHIFT ? process.env.REDSHIFT === 'true' : false,
    MODELSWITCH: process.env.MODEL_SWITCH ? process.env.MODEL_SWITCH === 'true' : false,
    BB_FLOW_V3_ENABLED: process.env.BB_FLOW_V3_ENABLED !== 'false',
    FEEDBACK_FILTERS: process.env.FEEDBACK_FILTERS ? process.env.FEEDBACK_FILTERS === 'true' : true,
    // front-end environment
    TITLE: process.env.TITLE,
    LOGO: process.env.LOGO,
    API_URL: process.env.API_URL ?? '',
    CURRENT_ENV: process.env.CURRENT_ENV || 'production',
    FRONTEND_VERSION: packageJSON.version,
    SHOW_REACT_QUERY_DEV_TOOLS: process.env.SHOW_REACT_QUERY_DEV_TOOLS === 'true'
  };
  try {
    if (!request.headers.get('Cookie')?.includes('session_id=')) throw new Error('No session_id cookie found');
    const api = new API({
      request,
      context,
      redirectOnError: true
    });
    const user = await api.users.readUserMe();
    return json({
      user,
      env
    });
  } catch {
    return json({
      user: null,
      env
    });
  }
};

// We're using this guide:
// https://github.com/kiliman/remix-hydration-fix/blob/main/app/root.tsx
// in order to remove hydration issues caused by rendering the entire
// document on the server.

export function Head({
  title
}) {
  _s();
  const [renderHead, setRenderHead] = useState(false);
  const hydrated = useHydrated();
  useEffect(() => {
    if (!hydrated) return;
    if (!renderHead) {
      // trigger re-render so we can remove the old head
      setRenderHead(true);
      return;
    }
    removeOldHead(document.head);
  }, [renderHead, hydrated]);
  return <>
      {/* allow specifying a title in the head */}
      {/* useful for Catch/Error Boundaries */}
      {/* must be first to override any from meta */}
      {title && <title>{title}</title>}
      <Meta />
    </>;
}
_s(Head, "NHUCYHvBGGqbF4sl5aOmUdlgKi8=", false, function () {
  return [useHydrated];
});
_c = Head;
function removeOldHead(parent = document.head) {
  let foundOldHeader = false;
  const nodesToRemove = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const node of parent.childNodes) {
    if (!foundOldHeader && node.nodeName !== '#comment') {
      continue;
    }
    if (foundOldHeader && node.nodeName === '#comment' && node.nodeValue === `end head`) {
      nodesToRemove.push(node);
      break;
    }
    // TODO: inspect why this is done this way. Within entry.server.tsx we add this:
    // <head><!--start head-->${head}<!--end head--></head><body>, but if we're able to
    // generate this content on the server to begin with, why are we not just creating an
    // element with a unique ID, and using that id in this code to remove it?
    if (foundOldHeader || node.nodeName === '#comment' && node.nodeValue === `start head`) {
      foundOldHeader = true;
      nodesToRemove.push(node);
    }
  }
  nodesToRemove.forEach(node => node.remove());
}
export default function App({
  title,
  children
}) {
  _s2();
  const navigation = useNavigation();
  const nonce = useNonce();
  useEffect(() => {
    // when the state is idle then we can complete the progress bar
    if (navigation.state === 'idle') NProgress.done();
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    else {
      const timeoutId = setTimeout(() => NProgress.start(), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [navigation.state]);
  return <>
      <Links />
      <ClientPortal getContainer={() => document.head}>
        <Head title={title} />
      </ClientPortal>
      {children || <Body />}
      <ScrollRestoration nonce={nonce} />
      <Scripts nonce={nonce} />
      <LiveReload nonce={nonce} />
    </>;
}
_s2(App, "9U4YYulKc63DDa3RQrzrj0K3lN4=", false, function () {
  return [useNavigation, useNonce];
});
_c2 = App;
function Body() {
  _s3();
  const env = useEnv();
  return <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <Toaster />
        <Outlet />
      </DndProvider>
      {env?.SHOW_REACT_QUERY_DEV_TOOLS === true && <ReactQueryDevtools initialIsOpen={false} client={queryClient} />}

    </QueryClientProvider>;
}
_s3(Body, "l6PtF9BFfK2Ln1fjy3dcp5yOHY0=", false, function () {
  return [useEnv];
});
_c3 = Body;
export function CatchBoundary() {
  _s4();
  const caught = useCatch();
  const logger = useLogger();
  const user = useOptionalUser();
  useRunOnce(() => {
    logger.error(`Legacy catch boundary hit`, caught);
  });
  return <App title='Access Denied'>
      <main className='flex h-full w-full flex-col items-center'>
        <article className='flex h-full max-w-md flex-col items-center justify-center text-center'>
          <AlertTriangle size={80} className='mb-3 text-gray-400' />
          <h3 className='mb-2 text-xl font-bold'>
            {caught.status} {caught.statusText}
          </h3>
          <p className='mt-4 text-sm text-gray-500 dark:text-gray-400'>
            It seems the resource you requested either does not exist or you do
            not have permission to access it. Either go back or switch to an
            account with access.
          </p>
          <BackFromErrorButton />
        </article>
        {user && <div className='mb-12'>
            <p className='mb-2 text-center text-xs text-gray-400 dark:text-gray-500'>
              You’re signed in as
            </p>
            <Link aria-label='Switch accounts' className='mx-auto flex w-min max-w-full items-center rounded-full border border-gray-200 dark:border-gray-700' to='/login?noRedirect='>

              <Avatar user={user} />
              <p className='ml-1.5 mr-2.5 truncate text-xs font-medium text-gray-500 dark:text-gray-400'>
                {user.email}
              </p>
            </Link>
          </div>}

      </main>
    </App>;
}
_s4(CatchBoundary, "JdBRPTc1twU5+9KisJWUqV2GWXk=", false, function () {
  return [useCatch, useLogger, useOptionalUser, useRunOnce];
});
_c4 = CatchBoundary;
export function ErrorBoundary({
  error
}) {
  return <App title='Oh no!'>
      <ErrorBoundaryContent error={error} />
    </App>;
}
_c5 = ErrorBoundary;
var _c, _c2, _c3, _c4, _c5;
$RefreshReg$(_c, "Head");
$RefreshReg$(_c2, "App");
$RefreshReg$(_c3, "Body");
$RefreshReg$(_c4, "CatchBoundary");
$RefreshReg$(_c5, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;